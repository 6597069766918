import {matchListActivate, showMatches} from "./winners";
import {closePopup, openPopup} from "./popup-utils";

const btnOpen = document.querySelector('.js-open')
const btnWinners = document.querySelector('.aside__winners--general')
const btnWinnersPersonal = document.querySelector('.aside__winners--personal')

export const formPopup = document.querySelector('.popup-dark--form')
export const winnersPopup = document.querySelector('.popup-dark--winners')
export const winnersPopupForecast = document.querySelector('.popup-dark--forecast')
export const winnersPopupForecastResult = document.querySelector('.popup-dark--forecast-result')
const contentsPopup = document.querySelectorAll('.popup-dark__content')

btnOpen?.addEventListener('click', evt => {
    evt.preventDefault()
    openPopup(formPopup)
})

btnWinners?.addEventListener('click', evt => {
    evt.preventDefault()
    openPopup(winnersPopup)
    showMatches()
})

btnWinnersPersonal?.addEventListener('click', evt => {
    evt.preventDefault()
    openPopup(winnersPopupForecast)
    // openPopup(winnersPopupForecastResult)
})

contentsPopup.forEach(content => {
    content.addEventListener('click', (evt) => {
        evt.stopPropagation()
    })
})

document.addEventListener('keydown', (evt) => {
    const keyName = evt.keyCode

    if (keyName === 27) {
        closePopup()
    }
})


